<template>
  <div class="login-main">

    <div class="row full-height justify-center items-center">
      <div class="bg-white" style="max-width: 450px">
        <q-card>
          <q-card-section class="q-py-none">
            <img src="/statics/login/lmslogo.png" class="q-pa-md" style="width: 100%">
          </q-card-section>
          <div v-if="success">
            <q-card-section>
              <div class="text-body2 text-center">{{ msg }}</div>
            </q-card-section>
          </div>
          <div v-else>
            <q-card-section>
              <div class="text-body2 text-center q-mb-lg">Please enter email address to unsubscribe</div>
              <q-input label="Email Address" v-model="email" />
            </q-card-section>
            <q-card-section class="row justify-center">
              <q-btn color="primary" @click="unsubscribe" :loading="loading">Unsubscribe</q-btn>
            </q-card-section>
          </div>
        </q-card>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'LoginPage',
  data () {
    return {
      email: null,
      success: false,
      loading: false
    }
  },
  methods: {
    async unsubscribe () {
      this.loading = true
      try {
        let res = await this.$axios.post(this.$consts.REPORT_UNSUBSCRIBE_URL, { email: this.email, report: this.$route.params.reportId })
        this.msg = res.data
        this.loading = false
        this.success = true
      } catch (e) {
        this.loading = false
        this.$failureMsg()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import url('https://rsms.me/inter/inter.css');
html *
  font-family 'Inter', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  font-smoothing antialiased
  color black

.login-main
  background #FFFFFF url(/statics/login/lmsbackground.jpg) no-repeat center center
  background-size cover
  height 100vh
</style>
